import React, { useState, useEffect } from "react";
import "../invoice/invoicedetails.css";
import baseurl from "../baseurl/baseurl";
import { FaRegPenToSquare } from "react-icons/fa6";
import CommonPage from "../native/commonpage";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function Product() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setcurrentcenter] = useState("");
  const baseUrl = baseurl.REACT_APP_BASE_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);


  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
  const startRowNumber =
    currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1;

  const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  // Calculate the middle page numbers based on the current page index
  if (totalPages <= 5) {
    // If there are 5 or fewer total pages, add all page numbers
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    // If the current page is within the first 3 pages, add the first 5 pages
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    // Show ellipsis to indicate more pages
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    // If the current page is within the last 2 pages, add the last 5 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    // If the current page is in the middle, show the current page and the previous and next 2 pages
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }

  // Add "prev" button if not on the first page
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }

  // Add "next" button if not on the last page
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data.status === "success" && data.data) {
        console.log(data.data);
        setInvoiceData(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // const handleInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const openModal = () => {
  //   setcurrentcenter("");
  //   document.getElementById("myModal").style.display = "block";
  // };

  // const closeModal = () => {
  //   document.getElementById("myModal").style.display = "none";
  //   clearFormData();
  // };

  const openModal = () => {
    setIsModalOpen(true);  // Set modal state to true
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Set modal state to false
  };


  const updateModal = (invoice) => {
    setcurrentcenter(invoice);
    document.getElementById("myupdateModal").style.display = "block";
  };

  const closeupdateModal = () => {
    document.getElementById("myupdateModal").style.display = "none";
    setcurrentcenter("");
    clearFormData();
  };

  // Add center
  const [formData, setFormData] = useState({
    id: null,
    productCode: "",
    productName: "",
    category: "",
    subCategory: "",
    businessUnitName: "",
    productType: "",
    inUse: "",
    taxInPercentage: null,
    name: "",
  });

  useEffect(() => {
    if (center) {
      setFormData({
        id: center.id || null,
        productCode: center.productCode || "",
        productName: center.productName || "",
        category: center.category || "",
        subCategory: center.subCategory || "",
        businessUnitName: center.businessUnitName || "",
        productType: center.productType || "",
        inUse: center.inUse || "",
        taxInPercentage: center.taxInPercentage || null,
        name: center.name || "",
      });
    }
  }, [center]);

  const clearFormData = () => {
    setFormData({
      id: null,
      productCode: "",
      productName: "",
      category: "",
      subCategory: "",
      businessUnitName: "",
      productType: "",
      inUse: "",
      taxInPercentage: null,
      name: "",
    });
  };

  const handleFaRegPenToSquareClick = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/products/${center.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to update product.");
      }
  
      const message = await response.text();
      console.log("Response Message:", message);
  
      // Close the modal only if it exists
      const modal = document.getElementById("myModal");
      if (modal) {
        modal.style.display = "none";
      } else {
        console.warn("Modal not found in DOM");
      }
  
      alert(message);
  
      // Clear form data
      clearFormData();
    } catch (error) {
      console.error("Error during update:", error);
      alert(`Error: ${error.message}`);
    }
  };
  

  // const addProduct = async () => {
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const response = await fetch(`${baseUrl}/products`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (!response.ok) {
  //       const errorResponse = await response.json();
  //       throw new Error(errorResponse.message);
  //     }

  //     const data = await response.json();
  //     document.getElementById("myModal").style.display = "none";
  //     alert("Successfully saved");

  //     // Reset form data
  //     clearFormData();
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     alert("Error:", error.message);
  //   }
  // };

  const addProduct = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${baseUrl}/products`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      // Check if the response is JSON or plain text
      const contentType = response.headers.get("Content-Type");
  
      let data;
      if (contentType && contentType.includes("application/json")) {
        data = await response.json(); // Parse JSON response
      } else {
        data = await response.text(); // If it's not JSON, parse as text
      }
  
      if (response.ok) {
        // If response is successful
        alert(data);  // Display the success message from response text
        document.getElementById("myModal").style.display = "none";
        clearFormData(); // Reset the form
      } else {
        // If the response is not successful
        throw new Error(data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert(`Error: ${error.message}`); // Show error message
    }
  };
  
  //   const currentItems = invoiceData
  //     .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  //   const totalPages = Math.ceil(invoiceData.length / itemsPerPage);

  //   const visiblePageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  //   const paginate = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        <div className="master-details-container">
          <div className="left-side">
            <h2>Product Details</h2>
          </div>
          <div className="right-side">
            <div className="buttons">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button className="generate-button" onClick={openModal}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="navbar">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Product Id</th>
                <th>Business Unit Name</th>
                <th>Category</th>
                <th>In Use</th>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Sub Category</th>
                <th>Tax(%)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.id}</td>
                  <td>{invoice.businessUnitName}</td>
                  <td>{invoice.category}</td>
                  <td>{invoice.inUse}</td>
                  <td>{invoice.productCode}</td>
                  <td>{invoice.productName}</td>
                  <td>{invoice.productType}</td>
                  <td>{invoice.subCategory}</td>
                  <td>{invoice.taxInPercentage}</td>
                  <td>
                    <div className="icon-container">
                      <FaRegPenToSquare
                        className="icon green"
                        onClick={() => updateModal(invoice)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {invoiceData.length > itemsPerPage && (
          <div className="pagination">
            {visiblePageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={
                  currentPage === number ? "active page-number" : "page-number"
                }
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>

      {isModalOpen && (
  <div
    id="myModal"
    className="modal"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    }}
  >
    <div
      className="modal-content-center"
      style={{
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        width: '100%',
      }}
    >
      {/* Header Container */}
      <div
        className="header-container"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Typography
          variant="h5"
          className="form-heading"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            flex: 1,
            margin: 0,
          }}
        >
          Add Product
        </Typography>
        <button
          className="close-button-center"
          onClick={closeModal}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
            cursor: 'pointer',
          }}
        >
          X
        </button>
      </div>

      <Box
        className="add-center-form-container"
        sx={{
          maxWidth: 800,
          margin: 'auto',
          padding: 3,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          backgroundColor: '#fff',
        }}
      >
        <form className="form-inline">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Code"
                name="productCode"
                placeholder="Enter Product Code"
                value={formData.productCode}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Name"
                name="productName"
                placeholder="Enter Product Name"
                value={formData.productName}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Category"
                name="category"
                placeholder="Enter Category"
                value={formData.category}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Sub-Category"
                name="subCategory"
                placeholder="Enter Sub-Category"
                value={formData.subCategory}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Business Unit Name"
                name="businessUnitName"
                placeholder="Enter Business Unit Name"
                value={formData.businessUnitName}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Type"
                name="productType"
                placeholder="Enter Product Type"
                value={formData.productType}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="inUse-label">In Use</InputLabel>
                <Select
                  labelId="inUse-label"
                  name="inUse"
                  value={formData.inUse}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Tax (%)"
                name="taxInPercentage"
                placeholder="Enter Tax in Percentage"
                value={formData.taxInPercentage || ''}
                onChange={handleInputChange}
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'center', marginTop: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addProduct}
              sx={{ marginRight: 2 }}
            >
              Add Product
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  </div>
)}

<>
  <div
    id="myupdateModal"
    className="modal"
    style={{
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    }}
  >
    <div
      className="modal-content-center"
      style={{
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography
          variant="h5"
          className="form-heading"
          style={{ margin: 0 }}
        >
          Update Product
        </Typography>
        <button
          className="close-button-center"
          onClick={closeupdateModal}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          X
        </button>
      </div>

      <Box
        className="add-center-form-container"
        sx={{
          maxWidth: 800,
          margin: 'auto',
          padding: 3,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          backgroundColor: '#fff',
        }}
      >
        <form className="form-inline">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="ID"
                name="id"
                placeholder="Enter Product ID"
                value={formData.id}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Code"
                name="productCode"
                placeholder="Enter Product Code"
                value={formData.productCode}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Name"
                name="productName"
                placeholder="Enter Product Name"
                value={formData.productName}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Category"
                name="category"
                placeholder="Enter Category"
                value={formData.category}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Sub-Category"
                name="subCategory"
                placeholder="Enter Sub-Category"
                value={formData.subCategory}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Business Unit Name"
                name="businessUnitName"
                placeholder="Enter Business Unit Name"
                value={formData.businessUnitName}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Product Type"
                name="productType"
                placeholder="Enter Product Type"
                value={formData.productType}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="inUse-label">In Use</InputLabel>
                <Select
                  labelId="inUse-label"
                  name="inUse"
                  value={formData.inUse}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Tax (%)"
                name="taxInPercentage"
                placeholder="Enter Tax in Percentage"
                value={formData.taxInPercentage || ''}
                onChange={handleInputChange}
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'center', marginTop: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFaRegPenToSquareClick}
              sx={{ marginRight: 2 }}
            >
              Update Product
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  </div>
</>


    </div>
  );
}

export default Product;
