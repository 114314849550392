import React, { useState, useEffect } from "react";
// import "../invoice/invoice.css";
import "./report-product.css";
import CommonPage from "../native/commonpage";
import Loader from "../baseurl/loader";
import * as XLSX from "xlsx";

function Invoicedetails() {
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFilter, setDateFilter] = useState("Today");

  // Set default value for customStartDate and customEndDate to today's date
  const today = new Date().toISOString().split("T")[0];
  const [customStartDate, setCustomStartDate] = useState(today);
  const [customEndDate, setCustomEndDate] = useState(today);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };
  useEffect(() => {
    // Trigger API call when dateFilter or custom dates change
    if (dateFilter === "15 Days") {
      const today = new Date();
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - 15); // Calculate 15 days ago

      setCustomStartDate(formatDate(pastDate));
      setCustomEndDate(formatDate(today));
      setError(""); // Clear error when preset range is selected
    } else if (dateFilter !== "Custom") {
      // Reset custom dates for non-Custom filters
      setCustomStartDate("");
      setCustomEndDate("");
    }
    fetchProductData();
  }, [dateFilter, customStartDate, customEndDate]);

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);

    if (value !== "Custom") {
      // Reset custom dates for non-custom filters
      setCustomStartDate("");
      setCustomEndDate("");
    }
  };

  const handleCustomDateChange = (setter) => (e) => {
    setter(e.target.value);

    // Trigger API call if both custom dates are filled
    if (customStartDate && customEndDate) {
      fetchProductData();
    }
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? null : date.toISOString().split("T")[0];
  };

  useEffect(() => {
    setFilteredInvoiceData(invoiceData);
  }, [invoiceData]);

  const fetchProductData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Redirecting to login page.");
      }

      // Default API parameters
      let startDate = customStartDate || today;
      let endDate = customEndDate || today;

      // Adjust dates based on filter
      if (dateFilter === "1 Week") {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        startDate = lastWeek.toISOString().split("T")[0];
        endDate = today;
      }

      const url = `https://cfsolivaclinic.com/api/employee/get-service-data?startDate=${startDate}&endDate=${endDate}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product data");
      }

      const data = await response.json();
      setInvoiceData(data.data); // Accessing the 'data' array
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = invoiceData.filter((invoice) => {
      return Object.values(invoice).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      });
    });

    const indexOfItem = filteredData.findIndex((item) =>
      Object.values(item).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(query);
        }
        return false; // Skip non-string values
      })
    );

    if (indexOfItem !== -1) {
      const newPage = Math.ceil((indexOfItem + 1) / itemsPerPage);
      setCurrentPage(newPage);
    } else {
      setCurrentPage(1);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (pageNumber !== "..." && typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInvoiceData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredInvoiceData.length / itemsPerPage);

  let visiblePageNumbers = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    for (let i = 1; i <= 5; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - 2) {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = totalPages - 4; i <= totalPages; i++) {
      visiblePageNumbers.push(i);
    }
  } else {
    visiblePageNumbers.push(1);
    visiblePageNumbers.push("...");
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      visiblePageNumbers.push(i);
    }
    visiblePageNumbers.push("...");
    visiblePageNumbers.push(totalPages);
  }
  if (currentPage > 1) {
    visiblePageNumbers.unshift("prev");
  }
  if (currentPage < totalPages) {
    visiblePageNumbers.push("next");
  }

  const Invoice = ({ invoice, index }) => {
    const {
      BusinessUnit,
      Card,
      Cash,
      Category,
      Center,
      CenterCode,
      Check,
      Date: InvoiceDate,
      EmployeeCode,
      EmployeeJobCode,
      EmployeeName,
      Gender,
      GiftCard,
      GrossInvoiceValue,
      GuestCode,
      GuestName,
      InvoiceCenter,
      InvoiceCenterCode,
      InvoiceDateFull,
      InvoiceDiscount,
      Invoice: InvoiceNo,
      Item,
      ItemCode,
      ItemDiscount,
      ItemName,
      ItemQuantity,
      NetInvoiceValue,
      TotalCollection,
      UnitPrice,
      Zone,
      SubCategory,
      Custom,
      LP,
      PrepaidCard,
      Receipt,
      PaymentNo,
      IsBundledProduct,
      NetPrice,
      RowNum,
      ItemRowNum,
      GuestEmail,
      GuestMembership,
    } = invoice;

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{formatDate(InvoiceDate)}</td>
        <td>{BusinessUnit}</td>
        <td>{Category}</td>
        <td>{SubCategory}</td>
        <td>{Center}</td>
        <td>{CenterCode}</td>
        <td>{Zone}</td>
        <td>{Card}</td>
        <td>{Cash}</td>
        <td>{Check}</td>
        <td>{Custom}</td>
        <td>{GiftCard}</td>
        <td>{PrepaidCard}</td>
        <td>{GrossInvoiceValue}</td>
        <td>{InvoiceDiscount}</td>
        <td>{NetInvoiceValue}</td>
        <td>{TotalCollection}</td>
        <td>{EmployeeCode}</td>
        <td>{EmployeeJobCode}</td>
        <td>{EmployeeName}</td>
        <td>{GuestCode}</td>
        <td>{GuestName}</td>
        <td>{Gender}</td>
        <td>{InvoiceCenter}</td>
        <td>{InvoiceCenterCode}</td>
        <td>{InvoiceNo}</td>
        <td>{Receipt}</td>
        <td>{PaymentNo}</td>
        <td>{Item}</td>
        <td>{ItemCode}</td>
        <td>{ItemName}</td>
        <td>{ItemQuantity}</td>
        <td>{UnitPrice}</td>
        <td>{ItemDiscount}</td>
        <td>{IsBundledProduct}</td>
        <td>{NetPrice}</td>
        <td>{RowNum}</td>
        <td>{ItemRowNum}</td>
        <td>{GuestEmail}</td>
        <td>{GuestMembership}</td>
        <td>{InvoiceDateFull}</td>
      </tr>
    );
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredInvoiceData); // Export all filtered items
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  return (
    <div className="new-page-container">
      <div className="common-page-content">
        <CommonPage />
      </div>
      <div className="other-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div>
          <div className="master-details-container">
            <div className="left-side">
              <h2>service Report</h2>
            </div>
            <div>
              <button className="green-button" onClick={exportToExcel}>
                Export
              </button>
            </div>

            <div className="right-side">
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <select
                    value={dateFilter}
                    onChange={handleDateFilterChange}
                    style={{
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "12px",
                    }}
                  >
                    <option value="Today">Today</option>
                    <option value="1 Week">1 Week</option>
                    <option value="15 Days">15 Days</option>
                    <option value="Custom">Custom Date</option>
                  </select>
                  {dateFilter === "Custom" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="date"
                        value={customStartDate}
                        onChange={handleCustomDateChange(setCustomStartDate)}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "12px",
                        }}
                      />
                      <input
                        type="date"
                        value={customEndDate}
                        onChange={handleCustomDateChange(setCustomEndDate)}
                        style={{
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "2px",
                          fontSize: "10px",
                        }}
                      />
                    </div>
                  )}
                  {error && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "10px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {error}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Business Unit</th>
                  <th>Category</th>
                  <th>SubCategory</th>
                  <th>Center</th>
                  <th>Center Code</th>
                  <th>Zone</th>
                  <th>Card</th>
                  <th>Cash</th>
                  <th>Check</th>
                  <th>Custom Amount</th>
                  <th>Gift Card</th>
                  <th>Prepaid Card</th>
                  <th>Gross Invoice Value</th>
                  <th>Invoice Discount</th>
                  <th>Net Invoice Value</th>
                  <th>Total Collection</th>
                  <th>Employee Code</th>
                  <th>Employee Job Code</th>
                  <th>Employee Name</th>
                  <th>Guest Code</th>
                  <th>Guest Name</th>
                  <th>Gender</th>
                  <th>Invoice Center</th>
                  <th>Invoice Center Code</th>
                  <th>Invoice No</th>
                  <th>Receipt</th>
                  <th>Payment No</th>
                  <th>Item</th>
                  <th>Item Code</th>
                  <th>Item Name</th>
                  <th>Item Quantity</th>
                  <th>Unit Price</th>
                  <th>Item Discount</th>
                  <th>Is Bundled Product</th>
                  <th>Net Price</th>
                  <th>Row Number</th>
                  <th>Item Row Number</th>
                  <th>Guest Email</th>
                  <th>Guest Membership</th>
                  <th>Invoice Date (Full)</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((invoice, index) => (
                  <Invoice
                    key={index}
                    invoice={invoice}
                    index={indexOfFirstItem + index}
                  />
                ))}
              </tbody>
            </table>
          </div>

          {filteredInvoiceData.length > itemsPerPage && (
            <div className="pagination">
              {visiblePageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={
                    typeof number === "number"
                      ? currentPage === number
                        ? "active page-number"
                        : "page-number"
                      : number === "prev" || number === "next"
                      ? "prev-next"
                      : "ellipsis"
                  }
                >
                  {number}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Invoicedetails;
